import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";

import dayjs from "dayjs";
import { CrudProperty } from "~/plugins/Cruxtify/CrudProperty";
import { AddressFilter } from "../filters/AddressFilter";
import { CrudFilter } from "../plugins/Cruxtify/CrudFilter";
import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import {
  BooleanField,
  DateField,
  DateTimeField,
  FieldGroupField,
  GoogleAddressField,
  LongTextField,
  MoneyField,
  NumberField,
  PhoneField,
  RelationshipField,
  RelationshipFieldMany,
  SelectField,
  StringField,
  UniqueField,
} from "../plugins/Cruxtify/field-types";
import {
  AddressField,
  AddressFieldsEnum,
} from "../plugins/Cruxtify/field-types/AddressField";
import {
  BooleanProperty,
  DateProperty,
  DateTimeProperty,
  MoneyProperty,
  NumberProperty,
  PhoneProperty,
  RelationshipProperty,
  RelationshipPropertyMany,
  StringProperty,
} from "../plugins/Cruxtify/property-types";
import { Account } from "./Account";
import { Contact } from "./Contact";
import { extendWithDeleted } from "./collection-layouts/extend-with-deleted";

const idDecorator = (val) => "CON-" + String(val).padStart(6, "0");

const baseCollectionLayout = {
  id: "table",
  columnFields: [
    "id",
    "estimate",
    "contact",
    "account",
    "city",
    "stage",
    "type",
    "start_date",
    "finish_date",
    "private_comments",
    "user",
  ],
  filters: [new AddressFilter({ name: "address" })],
  enableDynamicFilters: true,
  dynamicFilterOptions: {
    filterFields: [
      "user",
      "contact",
      "account",
      "address",
      "city",
      "state",
      "subdivision",

      "estimate",
      "stage",
      "estimate_date",
      "expiration_date",
      "accepted_date",
      "start_date",
      "finish_date",

      "estimate_comments",
      "private_comments",
      "weather",
      "foreman",
      "squares_used",
      "job_comments",

      "type",
      "res_descriptions",
      "flat",
      "blank",
      "pitch",
      "floors",
      "squares",
      "shingle_name",
      "shingle_type",
      "shingle_color",
      "replacement_contract",
      "vent_system_details",
      "cost_to_upgrade",
      "cost_to_insulate",
      "metal_edge",
      "cost_for_decking_repair",
      "estimate",
      "down_payment",
      "payment_terms",
      "warranty_length",

      "created_at",
      "author",
      "updated_at",
      "last_mod_author",
    ],
  },

  enableSearch: true,
  searchLabel: "Contract Number",
  useModal: true,
  remoteQueryOptions: {
    perPage: 25,
  },
  defaultSort: [
    {
      field: "id",
      order: "desc",
    },
  ],
};

export class Contract extends CrudModel {
  protected static _typeLabel = "Contract";
  protected static asProperty = "contract";
  public static api = {
    path: "contracts",
  };
  protected static routeBase = "contracts";
  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
      [UserRole.EntityOwner]: UserPermission.Edit,
      [UserRole.LoggedIn]: UserPermission.New,
    },
    UserPermission.Read
  );

  protected userIdField = ["user"];

  public static idDecorator = idDecorator;

  protected static propertyDefinitions = [
    {
      type: StringProperty,
      opts: {
        name: "id",
        label: "Contract Number",
        userPermissions: UserPermission.Read,
        decorator: idDecorator,
        computed: true,
      },
    },
    {
      type: NumberProperty,
      opts: {
        name: "legacy_id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: NumberProperty,
      opts: {
        name: "sort",
        label: "Sort",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "created_at",
        label: "Created At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "updated_at",
        label: "Updated At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "deleted_at",
        label: "Deleted At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "contact",
        label: "Contact",
        relatedModel: "Contact",
        rules: ["required"],
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "address",
        label: "Street",
        rules: ["required"],
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "city",
        rules: ["required"],
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "state",
        label: "State",
        maxLength: 2,
        rules: ["required"],
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "zip",
        label: "Zip",
        rules: ["required"],
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "subdivision",
        label: "Subdivision",
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "stage",
        label: "Stage",
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Edit,
            [UserRole.EntityOwner]: UserPermission.Edit,
            [UserRole.LoggedIn]: UserPermission.New,
          },
          UserPermission.Read
        ),
      },
    },

    {
      type: DateProperty,
      opts: {
        name: "estimate_date",
        label: "Estimate Date",
        default: () => new Date(),
      },
    },
    {
      type: DateProperty,
      opts: {
        name: "expiration_date",
        label: "Expiration Date",
        default: () => dayjs().add(1, "month"),
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "type",
        label: "Type",
        rules: ["required"],
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "description",
        label: "Description",
        rules: ["required"],
      },
    },
    {
      type: BooleanProperty,
      opts: {
        name: "flat",
        label: "Flat",
      },
    },
    {
      type: BooleanProperty,
      opts: {
        name: "blank_proposal",
        label: "Blank Proposal",
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "pitch",
        label: "Pitch",
        rules: ["required"],
      },
    },
    {
      type: NumberProperty,
      opts: {
        name: "floors",
        label: "Floors",
      },
    },
    {
      type: MoneyProperty,
      opts: {
        name: "estimate",
        label: "Estimate",
        rules: ["required"],
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "squares",
        label: "Squares",
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "shingle_name",
        label: "Shingle Name",
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "shingle_type",
        label: "Shingle Type",
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "shingle_color",
        label: "Shingle Color",
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "cost_to_upgrade",
        label: "Upgrade (Tear Off only)",
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "cost_to_insulate",
        label: "Insulate",
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "metal_edge",
        label: "Metal Edge",
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "cost_for_decking_repair",
        label: "Decking Replacement Per Sq Ft (Tear Off only)",
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "vent_system_details",
        label: "Vent System Details",
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "warranty_length",
        label: "Warranty Length",
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "down_payment",
        label: "Down Payment",
        default: "50%",
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "payment_terms",
        label: "Payment Due",
        default: "upon completion",
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "estimate_comments",
        label: "Estimate Comments",
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "private_comments",
        label: "Private Comments",
      },
    },
    {
      type: DateProperty,
      opts: {
        name: "accepted_date",
        label: "Accepted Date",
      },
    },
    {
      type: DateProperty,
      opts: {
        name: "start_date",
        label: "Start Date",
      },
    },
    {
      type: DateProperty,
      opts: {
        name: "finish_date",
        label: "Finish Date",
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "weather",
        label: "Weather",
      },
    },
    {
      type: PhoneProperty,
      opts: {
        name: "phone",
        label: "Phone",
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "foreman",
        label: "Foreman",
      },
    },
    {
      type: NumberProperty,
      opts: {
        name: "squares_used",
        label: "Squares Used",
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "job_comments",
        label: "Job Comments",
      },
    },
    {
      type: NumberProperty,
      opts: {
        name: "latitude",
        label: "Latitude",
        userPermissions: UserPermission.Read,
      },
    },
    {
      type: NumberProperty,
      opts: {
        name: "longitude",
        label: "Longitude",
        userPermissions: UserPermission.Read,
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "attachments",
        label: "Files",
        relatedModel: "Attachment",
        foreignProperty: "contract",
        userPermissions: UserPermission.Edit,
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "invoices",
        label: "Invoices",
        relatedModel: "Invoice",
        foreignProperty: "contract",
        userPermissions: UserPermission.Edit,
        newModelDefaults: (contract) => {
          return {
            user: contract.getProperty("user").value,
            total: contract.getProperty("estimate").value,
          };
        },
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "expenses",
        label: "Expenses",
        relatedModel: "Expense",
        foreignProperty: "contract",
        userPermissions: UserPermission.Edit,
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "comments",
        label: "Comments",
        relatedModel: "Comment",
        foreignProperty: "contract",
        userPermissions: UserPermission.Edit,
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "replacement_contract",
        label: "Replacement Option Contract",
        relatedModel: "Contract",
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "last_mod_author",
        label: "Last Updated By",
        relatedModel: "User",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "author",
        label: "Author",
        relatedModel: "User",
        userPermissions: new UserPermissions(
          // {
          //   [UserRole.SuperAdmin]: UserPermission.Edit
          // },
          UserPermission.Read
        ),
        computed: true,
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "user",
        label: "Owner",
        relatedModel: "User",
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Edit,
          },
          UserPermission.Read
        ),
        sortable: true,
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "account",
        label: "Account",
        relatedModel: "Account",
        computed: true,
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "notifications",
        label: "Notifications",
        relatedModel: "Notification",
        foreignProperty: "contract",
        userPermissions: UserPermission.Read,
      },
    },
    // {
    //   type: RelationshipPropertyMany,
    //   opts: {
    //     name: "events",
    //     label: "Appointments",
    //     relatedModel: "Event",
    //     foreignProperty: "contract"
    //   }
    // }
  ];

  protected static fieldDefinitions = [
    {
      type: StringField,
      opts: {
        property: "id",
        isPrimaryLabelField: true,
        userPermissions: UserPermission.Read,
        cols: 12,
      },
    },
    {
      type: SelectField,
      opts: {
        property: "stage",
        options: [
          "Estimate Complete",
          "Estimate Accepted",
          "Job In Process",
          "Job Complete",
        ],
        md: 12,
      },
    },
    {
      type: FieldGroupField,
      opts: {
        id: "description",
        label: "Description",
        subFields: [
          {
            type: SelectField,
            opts: {
              id: "res_descriptions",
              property: "description",
              options: [
                "Tear Off",
                "Redeck",
                "Recover",
                "Repair",
                "LAR",
                "New Construction",
              ],
              rules: ["required"],
              cols: 6,
              conditional: {
                property: "type",
                value: "Residential",
              },
            },
          },
          {
            type: SelectField,
            opts: {
              id: "com_descriptions",
              property: "description",
              options: [
                "Tear Off",
                "Recover",
                "Repair",
                "LAR",
                "New Construction",
              ],
              rules: ["required"],
              cols: 6,
              conditional: {
                property: "type",
                value: "Commercial",
              },
            },
          },
        ],
      },
    },
    {
      type: SelectField,
      opts: {
        property: "shingle_type",
        label: "Shingle Type",
        options: [
          "25YR 3 Tab",
          "30YR Laminate",
          "40YR Laminate",
          "50YR Laminate",
          "Architectural",
          "Other",
          "N/A",
        ],
        conditional: {
          conditionals: [
            {
              property: "type",
              value: "Residential",
            },
            {
              property: "description",
              compare: "notOneOf",
              value: ["New Construction", "LAR"],
            },
          ],
        },
      },
    },
    {
      type: SelectField,
      opts: {
        property: "warranty_length",
        label: "Warranty Length",
        options: {
          "10": "10-year",
          "5": "5-year",
          "2": "2-year",
          "1": "1-year",
        },
        rules: ["required"],
        conditional: {
          property: "description",
          compare: "notEquals",
          value: "Repair",
        },
      },
    },
    {
      type: AddressField,
      opts: {
        id: "map_link",
        viewComponentName: "GoogleLink",
        label: "Map",
        afterLabelComponents: ["google-link-button"],
        disabledSubFields: [
          AddressFieldsEnum.STREET_2,
          AddressFieldsEnum.DIVISION_2,
          AddressFieldsEnum.COUNTRY,
        ],
        subFieldOpts: {
          [AddressFieldsEnum.STREET_1]: {
            property: "address",
          },
          [AddressFieldsEnum.CITY]: {
            property: "city",
          },
          [AddressFieldsEnum.DIVISION_1]: {
            property: "state",
          },
          [AddressFieldsEnum.POSTAL_CODE]: {
            property: "zip",
          },
          [AddressFieldsEnum.LATITUDE]: {
            property: "latitude",
          },
          [AddressFieldsEnum.LONGITUDE]: {
            property: "longitude",
          },
        },
      },
    },
    {
      type: UniqueField,
      opts: {
        apiMethodName: "getUniqueAddress",
        id: "unique_address",
        fieldWidth: "full",
        label: "Address",
        seamless: true,
        subFields: [
          {
            type: GoogleAddressField,
            opts: {
              id: "address_group",
              seamless: true,
              disabledSubFields: [
                AddressFieldsEnum.STREET_2,
                AddressFieldsEnum.DIVISION_2,
                AddressFieldsEnum.COUNTRY,
              ],
              subFieldOpts: {
                [AddressFieldsEnum.STREET_1]: {
                  property: "address",
                  id: "address",
                  label: "Street",
                  md: 12,
                  rules: ["required"],
                },
                [AddressFieldsEnum.CITY]: {
                  property: "city",
                  id: "city",
                  md: 6,
                  rules: ["required"],
                },
                [AddressFieldsEnum.DIVISION_1]: {
                  property: "state",
                  id: "state",
                  label: "State",
                  md: 3,
                  rules: ["required"],
                },
                [AddressFieldsEnum.POSTAL_CODE]: {
                  property: "zip",
                  id: "zip",
                  label: "Zip",
                  md: 3,
                  rules: ["required"],
                },
                [AddressFieldsEnum.LATITUDE]: {
                  property: "latitude",
                  id: "latitude",
                },
                [AddressFieldsEnum.LONGITUDE]: {
                  property: "longitude",
                  id: "longitude",
                },
              },
            },
          },
        ],
      },
    },

    { type: NumberField, opts: { property: "legacy_id" } },
    { type: NumberField, opts: { property: "sort" } },
    { type: DateTimeField, opts: { property: "created_at" } },
    { type: DateTimeField, opts: { property: "updated_at" } },
    { type: DateTimeField, opts: { property: "deleted_at" } },
    { type: RelationshipField, opts: { property: "contact", md: 6 } },
    { type: StringField, opts: { property: "subdivision", md: 12 } },
    { type: DateField, opts: { property: "estimate_date", md: 12 } },
    { type: DateField, opts: { property: "expiration_date", md: 12 } },
    {
      type: SelectField,
      opts: {
        property: "type",
        options: ["Residential", "Commercial"],
        md: 6,
      },
    },
    {
      type: BooleanField,
      opts: {
        id: "flat_com",
        property: "flat",
        rules: ["mustBeTrue"],
        conditional: {
          property: "type",
          value: "Commercial",
        },
      },
    },
    {
      type: BooleanField,
      opts: {
        id: "flat_res",
        property: "flat",
        conditional: {
          property: "type",
          value: "Residential",
        },
      },
    },
    {
      type: BooleanField,
      opts: {
        id: "blank_com",
        property: "blank_proposal",
        rules: ["mustBeTrue"],
        conditional: {
          property: "type",
          value: "Commercial",
        },
      },
    },
    {
      type: BooleanField,
      opts: {
        id: "blank_res",
        property: "blank_proposal",
        conditional: {
          property: "type",
          value: "Residential",
        },
      },
    },
    { type: StringField, opts: { property: "pitch", md: 4 } },
    { type: NumberField, opts: { property: "floors", md: 4 } },
    { type: StringField, opts: { property: "squares", md: 4 } },
    {
      type: PhoneField,
      opts: {
        property: "phone",
        cols: 12,
        default: (prop: CrudProperty) => {
          if (prop.model && prop.model.getProperty("contact").value)
            return prop.model
              .getProperty("contact")
              .typedValue.getProperty("phone").value;
        },
      },
    },
    {
      type: MoneyField,
      opts: { property: "estimate", cols: 12, md: 6, rules: ["required"] },
    },
    { type: StringField, opts: { property: "down_payment", md: 6 } },
    {
      type: StringField,
      opts: {
        property: "shingle_name",
        conditional: {
          conditionals: [
            {
              property: "type",
              value: "Residential",
            },
            {
              property: "description",
              compare: "notOneOf",
              value: ["New Construction", "LAR"],
            },
          ],
        },
      },
    },
    {
      type: StringField,
      opts: {
        property: "shingle_color",
        conditional: {
          conditionals: [
            {
              property: "type",
              value: "Residential",
            },
            {
              property: "description",
              compare: "notOneOf",
              value: ["New Construction", "LAR"],
            },
          ],
        },
      },
    },
    {
      type: StringField,
      opts: {
        property: "cost_to_upgrade",
        conditional: {
          conditionals: [
            {
              property: "type",
              value: "Residential",
            },
            {
              property: "description",
              compare: "notOneOf",
              value: ["New Construction", "LAR"],
            },
          ],
        },
      },
    },
    {
      type: StringField,
      opts: {
        property: "cost_to_insulate",
        conditional: {
          conditionals: [
            {
              property: "type",
              value: "Residential",
            },
            {
              property: "description",
              compare: "notOneOf",
              value: ["New Construction", "LAR"],
            },
          ],
        },
      },
    },
    {
      type: StringField,
      opts: {
        property: "metal_edge",
        conditional: {
          conditionals: [
            {
              property: "type",
              value: "Residential",
            },
            {
              property: "description",
              compare: "notOneOf",
              value: ["New Construction", "LAR"],
            },
          ],
        },
      },
    },
    {
      type: StringField,
      opts: {
        property: "cost_for_decking_repair",
        rules: ["required"],
        conditional: {
          conditionals: [
            {
              property: "type",
              value: "Residential",
            },
            {
              property: "description",
              compare: "notOneOf",
              value: ["New Construction", "LAR"],
            },
          ],
        },
      },
    },
    {
      type: LongTextField,
      opts: {
        property: "vent_system_details",
        md: 12,
        conditional: {
          conditionals: [
            {
              property: "type",
              value: "Residential",
            },
            {
              property: "description",
              compare: "oneOf",
              value: ["Tear Off", "Redeck", "Recover"],
            },
          ],
        },
      },
    },
    { type: StringField, opts: { property: "payment_terms" } },
    { type: LongTextField, opts: { property: "estimate_comments" } },
    { type: LongTextField, opts: { property: "private_comments" } },
    { type: DateField, opts: { property: "accepted_date", md: 12 } },
    { type: DateField, opts: { property: "start_date", md: 12 } },
    { type: DateField, opts: { property: "finish_date", md: 12 } },
    { type: StringField, opts: { property: "weather" } },
    { type: StringField, opts: { property: "foreman" } },
    { type: NumberField, opts: { property: "squares_used" } },
    { type: LongTextField, opts: { property: "job_comments" } },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "attachments",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
        collectionLayoutId: "asRelationship",
      },
    },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "invoices",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
        collectionLayoutId: "asRelationship",
      },
    },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "expenses",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
        collectionLayoutId: "asRelationship",
      },
    },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "comments",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
        collectionLayoutId: "asRelationship",
      },
    },
    {
      type: RelationshipField,
      opts: {
        property: "replacement_contract",
        conditional: {
          conditionals: [
            {
              property: "type",
              value: "Residential",
            },
            {
              property: "description",
              compare: "notOneOf",
              value: ["New Construction", "LAR"],
            },
          ],
        },
      },
    },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "notifications",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
        collectionLayoutId: "asRelationship",
      },
    },
    { type: RelationshipField, opts: { property: "last_mod_author" } },
    // {
    //   type: RelationshipFieldMany,
    //   opts: {
    //     property: "events",
    //     viewComponentName: "form-relationship-field-many-table",
    //     formComponentName: "form-relationship-field-many-table",
    //     collectionLayoutId: "asRelationship"
    //   }
    // },
    { type: RelationshipField, opts: { property: "author" } },
    { type: RelationshipField, opts: { property: "account" } },
    {
      type: RelationshipField,
      opts: { property: "user", md: 6, sortable: true },
    },
  ];

  protected static modelLayoutDefinitions = [
    {
      type: "TabbedModelLayout",
      opts: {
        id: "Crudform",
        navigateAfterSave: false,
        headerComponents: ["ContractPDFButton"],
        tabs: [
          {
            id: "overviewTab",
            label: "Overview",
            content: {
              type: "FieldSetGridLayout",
              opts: {
                fieldSets: [
                  {
                    id: "0",
                    md: 6,
                    xl: 5,
                    fields: [
                      "id",
                      "user",
                      "contact",
                      "phone",
                      "account",
                      "unique_address",
                      "subdivision",
                    ],
                  },
                  {
                    id: "progress",
                    md: 3,
                    xl: 4,
                    fields: [
                      "estimate",
                      "stage",
                      "estimate_date",
                      "expiration_date",
                      "accepted_date",
                      "start_date",
                      "finish_date",
                    ],
                  },

                  {
                    id: "meta",
                    md: 3,
                    fields: [
                      "created_at",
                      "author",
                      "updated_at",
                      "last_mod_author",
                    ],
                  },
                ],
              },
            },
          },
          {
            id: "estimateTab",
            label: "Estimate",
            content: {
              type: "FieldSetGridLayout",
              opts: {
                fieldSets: [
                  {
                    id: "est",
                    md: 6,
                    xl: 6,
                    fields: [
                      "type",
                      "description",
                      "flat_res",
                      "blank_res",
                      "flat_com",
                      "blank_com",
                      "pitch",
                      "floors",
                      "squares",
                      "shingle_name",
                      "shingle_type",
                      "shingle_color",
                      "replacement_contract",
                      "vent_system_details",
                      "cost_to_upgrade",
                      "cost_to_insulate",
                      "metal_edge",
                      "cost_for_decking_repair",
                      "estimate",
                      "down_payment",
                      "payment_terms",
                      "warranty_length",
                    ],
                  },
                  {
                    id: "1",
                    md: 6,
                    xl: 6,
                    fields: [
                      "estimate_comments",
                      "private_comments",
                      "weather",
                      "foreman",
                      "squares_used",
                      "job_comments",
                    ],
                  },
                ],
              },
            },
          },
          // {
          //   id: "apptTab",
          //   label: "Appointments",
          //   content: {
          //     type: "FieldSetGridLayout",
          //     opts: {
          //       fieldSets: [
          //         {
          //           id: "4",
          //           cols: 12,
          //           fields: ["events"]
          //         }
          //       ]
          //     }
          //   }
          // },
          {
            id: "invoiceTab",
            label: "Invoice/Expenses",
            content: {
              type: "FieldSetGridLayout",
              opts: {
                fieldSets: [
                  {
                    id: "4",
                    md: 12,
                    xl: 6,
                    fields: ["invoices"],
                  },
                  {
                    id: "3",
                    md: 12,
                    xl: 6,
                    fields: ["expenses"],
                  },
                ],
              },
            },
          },
          {
            id: "attachmentsTab",
            label: "Files",
            content: {
              type: "FieldSetGridLayout",
              opts: {
                fieldSets: [
                  {
                    id: "6",
                    md: 12,
                    fields: ["attachments"],
                  },
                ],
              },
            },
          },
          {
            id: "commentsTab",
            label: "Comments",
            content: {
              type: "FieldSetGridLayout",
              opts: {
                fieldSets: [
                  {
                    id: "5",
                    md: 12,
                    fields: ["comments"],
                  },
                ],
              },
            },
          },
          {
            id: "notificationsTab",
            label: "Notifications",
            content: {
              type: "FieldSetGridLayout",
              opts: {
                fieldSets: [
                  {
                    id: "notifications",
                    fields: ["notifications"],
                  },
                ],
              },
            },
          },
        ],
      },
    },
  ];

  protected static collectionLayoutDefinitions = extendWithDeleted([
    baseCollectionLayout,

    {
      id: "asRelationship",
      useModal: false,
      columnFields: [
        "id",
        "stage",
        "type",
        "start_date",
        "finish_date",
        "estimate",
        "user",
      ],
      defaultSort: [
        {
          field: "id",
          order: "desc",
        },
      ],
    },

    {
      ...baseCollectionLayout,
      id: "completedJobs",
      columnFields: [
        ...baseCollectionLayout.columnFields.filter(
          (c) =>
            ["stage", "start_date", "private_comments"].includes(c) === false
        ),
        "phone",
      ],
      filters: [
        ...baseCollectionLayout.filters,
        new CrudFilter({
          name: "completedJobs",
          query: {
            stage: "Job Complete",
          },
        }),
      ],
      defaultSort: [
        {
          field: "finish_date",
          order: "desc",
        },
      ],
    },
  ]);

  public static mapQuery(query: {
    latitude?: number;
    longitude?: number;
    radius?: number;
    age?: number;
    color?: string;
    type?: string;
  }) {
    return this.$nuxt.$api.GET("map-query", query).then((res) => {
      if (res.data) return res.data;

      return res;
    });
  }

  public static getSchedule(query = {}) {
    return this.$nuxt.$api.GET("jobs-schedule", query).then((res) => {
      if (res.data)
        return {
          ...res,
          dataHydrated: res.data.map((data) => new Contract(data)),
        };

      return res;
    });
  }

  public static updateSchedule(idsOrdered = []) {
    return this.$nuxt.$api
      .POST("jobs-schedule", { contracts: idsOrdered.join(",") })
      .then((res) => {
        if (res.data) return res.data;

        return res;
      });
  }

  public static normalizeAddressFields(query, addressFieldsMap) {
    const addressFields = Object.keys(addressFieldsMap);
    const address = {};

    addressFields.forEach((field) => {
      if (query[field]) address[addressFieldsMap[field]] = query[field];
    });

    return { ...query, ...address };
  }

  public static getUniqueAddress(query = {}) {
    query = this.normalizeAddressFields(query, {
      billing_address: "address",
      billing_city: "city",
      billing_state: "state",
      billing_zip: "zip",
    });

    return this.$nuxt.$api.GET("unique-address", query).then((res) => {
      if (res.data.error) return { error: res.data.error };

      const dataHydrated = [
        ...res.data.contracts.map((data) => new Contract(data)),
        ...res.data.contacts.map((data) => new Contact(data)),
        ...res.data.accounts.map((data) => new Account(data)),
      ];

      return {
        dataHydrated,
      };
    });
  }
}
