import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import {
  DateField,
  DateTimeField,
  LongTextField,
  NumberField,
  RelationshipField,
  StringField,
} from "../plugins/Cruxtify/field-types";
import {
  DateProperty,
  DateTimeProperty,
  NumberProperty,
  RelationshipProperty,
  StringProperty,
} from "../plugins/Cruxtify/property-types";
import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";

export class Task extends CrudModel {
  protected static _typeLabel = "Task";
  protected static asProperty = "task";
  public static api = {
    path: "tasks",
  };
  protected static routeBase = "tasks";
  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
      [UserRole.EntityOwner]: UserPermission.Edit,
      [UserRole.LoggedIn]: UserPermission.New,
    },
    UserPermission.Read
  );

  protected static propertyDefinitions = [
    {
      type: NumberProperty,
      opts: {
        name: "id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "deleted_at",
        label: "Deleted At",
        userPermissions: UserPermission.Read,
      },
    },
    {
      type: NumberProperty,
      opts: {
        name: "legacy_id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "created_at",
        label: "Created At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "updated_at",
        label: "Updated At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "user",
        label: "Owner",
        relatedModel: "User",
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Edit,
          },
          UserPermission.Hidden
        ),
      },
    },
    {
      type: DateProperty,
      opts: {
        name: "due_date",
        label: "Due Date",
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "subject",
        label: "Subject",
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "description",
        label: "Description",
      },
    },

    {
      type: RelationshipProperty,
      opts: {
        name: "last_mod_author",
        label: "Last Updated By",
        relatedModel: "User",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "author",
        label: "Author",
        relatedModel: "User",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
  ];

  protected static fieldDefinitions = [
    { type: NumberField, opts: { property: "id" } },
    { type: DateTimeField, opts: { property: "created_at" } },
    { type: DateTimeField, opts: { property: "updated_at" } },
    { type: RelationshipField, opts: { property: "user" } },
    { type: DateField, opts: { property: "due_date" } },
    { type: StringField, opts: { property: "subject" } },
    { type: LongTextField, opts: { property: "description" } },
    { type: RelationshipField, opts: { property: "last_mod_author" } },
    { type: RelationshipField, opts: { property: "author" } },
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      columnFields: [
        "created_at",
        "user",
        "due_date",
        "subject",
        "description",
      ],
      defaultSort: [
        {
          field: "created_at",
          order: "desc",
        },
      ],
    },
  ];
}
