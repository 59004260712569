
import { CrudField } from "../../CrudField";

export default {
  props: {
    field: {
      type: CrudField,
      required: true,
    },
    inputProps: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    valueIsNone() {
      return this.field.isEmpty;
    },
    noneText() {
      return this.$config.readonlyNoneDisplay;
    },
  },
};
