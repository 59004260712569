
import { DataTableLayout } from "../../../../layouts/collection/DataTableLayout";

export default {
  data() {
    return {
      inlineFields: {},
    };
  },

  props: {
    item: {
      type: Object,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    layout: {
      type: DataTableLayout,
      required: true,
    },
    showModelActions: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    onRowClick(event) {
      if (!this.rowIsClickable) return;

      if (event.metaKey || event.ctrlKey) {
        event.preventDefault(); // Prevent normal navigation
        this.openInNewTab();
        return;
      }

      this.$emit("edit-item", this.item.id);
    },
    onMouseDown(event) {
      if (!this.rowIsClickable) return;

      // Handle Middle Click (Mouse Button 1)
      if (event.button === 1) {
        event.preventDefault(); // Prevent possible drag behavior
        this.openInNewTab();
      }
    },
    openInNewTab() {
      window.open(this.item.instance.getRouteSingle(), "_blank");
    },

    cacheInlineFields() {
      this.inlineFields = {};

      this.columns
        .filter((column) => column.field)
        .forEach((column) => {
          const model = this.item.instance;
          const isReadMode =
            !this.layout.inlineEdit ||
            (this.layout.inlineEditFields.length > 0 &&
              this.layout.inlineEditFields.indexOf(column.value) === -1);

          const field = model.findField(column.value);

          this.inlineFields[column.value] = {
            field,
            isReadMode,
          };
        });
    },

    updateRow(update) {
      this.item = Object.assign({}, this.item, update);
    },

    setRowSaving() {
      this.updateRow({ apiStatus: "saving" });
    },

    setRowSaved() {
      this.updateRow({ apiStatus: "saved" });
    },

    inlineEditChanged() {
      if (!this.showModelActions) return;

      this.setRowSaving();

      const item = rowData.instance;

      item.save().then(() => this.setRowSaved(rowData));
    },

    rowChildSaved(rowData) {
      this.expanded = [];
      this.setRowSaved(rowData);
    },
  },
  computed: {
    rowIsMarkedForDeletion() {
      return this.layout.collection.isMarkedForDeletion(this.item.id);
    },
    rowPropertyClasses() {
      return this.layout.rowClassProperties
        .map((prop) => prop + "--" + this.item.instance.prop(prop))
        .reduce((acc, val) => {
          acc[val] = true;
          return acc;
        }, {});
    },
    rowIsClickable() {
      return (
        !this.layout.expansionEnabled &&
        !this.layout.inlineEdit &&
        !this.layout.disableRowClick
      );
    },
  },
  watch: {
    columns: {
      immediate: true,
      handler() {
        this.cacheInlineFields();
      },
    },
  },
};
