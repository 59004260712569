import _ from "lodash";
import { CrudFieldQuery } from "./CrudField";
import { CrudFilter } from "./CrudFilter";
import { CrudModel, CrudModelType, IToPlainObjectOpts } from "./CrudModel";
import { CrudProperty, CrudPropertyQuery } from "./CrudProperty";
import {
  DynamicFilterController,
  DynamicFilterOptions,
} from "./filters/DynamicFilterController";

import { cache } from "./api/cache";

export interface ICrudCollection {
  model: CrudModelType;
  value?: ICrudCollectionValues[];
  remoteTotalItems?: number;
  remoteQuery?: boolean;
  remoteQueryOptions?: ICollectionRemoteQueryOptions;
  remoteQueryFilters?: CrudFilter[];
  fetchMethod?: string;
  defaultSort?: SortObjectArray;
  newModelDefaults?: Record<string, any> | Function;
  dynamicFilterOptions?: DynamicFilterOptions;
}

export interface ICollectionRemoteQueryOptions {
  page?: number;
  perPage?: number;
  sortBy?: CrudPropertyQuery[];
  sortDesc?: number[];
}

export type CoercedCollectionItemValue = {
  id: number;
  isNew: boolean;
  model?: CrudModel;
  modelPromise: Promise<CrudModel>;
};

export type ICrudCollectionItemValue = CrudModel | object | number;

export type ICrudCollectionValues =
  | ICrudCollectionItemValue[]
  | ICrudCollectionItemValue
  | null;

export type SortObjectArray = {
  property?: CrudPropertyQuery;
  field?: CrudFieldQuery;
  order: string;
}[];

const defaultRemoteQueryOptions = {
  page: 1,
  perPage: 25,
  sortBy: [],
  sortDesc: [],
};

export class CrudCollection {
  public loading = false;
  public model: CrudModelType;
  public lastFetch: Date | null = null;
  public remoteQuery: boolean = true;
  public remoteQueryOptions: {
    page?: number;
    perPage?: number;
    sortBy?: CrudProperty[];
    sortDesc?: number[];
  } = {};
  public remoteTotalItems: number | null = null;
  public remoteQueryFilters: CrudFilter[] = [];
  protected fetchMethod: string = "get";

  protected _instances: CrudModel[] = [];
  public get instances() {
    return this._instances.filter((instance) => !instance.isDeleted);
  }

  protected static $nuxt;
  public static setNuxtContext(context) {
    this.$nuxt = context;
  }
  public get $nuxt() {
    return this._classRef.$nuxt;
  }
  protected get _classRef() {
    return Object.getPrototypeOf(this).constructor;
  }

  public dynamicFilterController: DynamicFilterController;

  protected _opts: ICrudCollection;
  public get opts() {
    return this._opts;
  }

  constructor(opts: ICrudCollection) {
    this._opts = opts;

    this.model = opts.model;

    if (typeof opts.value !== "undefined") {
      this.set(opts.value, true);
    }

    if (typeof opts.remoteQuery !== "undefined")
      this.remoteQuery = opts.remoteQuery;

    if (!this.remoteQuery) this.lastFetch = new Date();

    if (typeof opts.remoteTotalItems !== "undefined")
      this.remoteTotalItems = opts.remoteTotalItems;

    if (typeof opts.fetchMethod !== "undefined")
      this.fetchMethod = opts.fetchMethod;

    if (typeof opts.newModelDefaults !== "undefined")
      this.newModelDefaults = opts.newModelDefaults;

    this.resetQueryOptionsExcept();

    if (typeof opts.remoteQueryFilters !== "undefined" && this.remoteQuery)
      this.remoteQueryFilters = [
        ...this.remoteQueryFilters,
        ...opts.remoteQueryFilters,
      ];

    this.dynamicFilterController = new DynamicFilterController({
      name: "df__",
      model: this.model,
      dynamicFilterOptions: opts.dynamicFilterOptions,
    });

    this.applyStagedFilters();
  }

  protected get defaultRemoteQueryOptions() {
    return Object.assign({}, defaultRemoteQueryOptions, {
      perPage: this.$nuxt.$config.defaultCollectionPerPage,
    });
  }

  protected getOriginalRemoteQueryOptions() {
    let queryOptions = this.defaultRemoteQueryOptions;
    if (this._opts.remoteQueryOptions) {
      queryOptions = Object.assign(
        {},
        queryOptions,
        this._opts.remoteQueryOptions
      );
    }

    return queryOptions;
  }

  public resetQueryOptionsExcept(optionKeysToSkip: string[] = []) {
    const originalOpts = this.getOriginalRemoteQueryOptions();

    Object.keys(this.remoteQueryOptions).forEach((key) => {
      if (optionKeysToSkip.includes(key))
        originalOpts[key] = this.remoteQueryOptions[key];
      else originalOpts[key] = this.defaultRemoteQueryOptions[key];
    });

    this.remoteQueryOptions = originalOpts;

    if (
      typeof this._opts.defaultSort !== "undefined" &&
      !optionKeysToSkip.includes("sortBy")
    ) {
      this.applySortObjectArray(this._opts.defaultSort);
    }
  }

  public applySortObjectArray(sortObjectArray: SortObjectArray) {
    if (!sortObjectArray || !this.remoteQuery) return;

    this.setQueryOption(
      "sortBy",
      sortObjectArray.map((sort) => {
        if (sort.property) return sort.property;
        if (sort.field) return this.model.findField(sort.field)?.property;
      }) as CrudPropertyQuery[]
    );

    this.setQueryOption(
      "sortDesc",
      sortObjectArray.map((sort) => (sort.order === "desc" ? 1 : 0))
    );
  }

  public async set(value: ICrudCollectionValues, skipMarkingAsUnsaved = false) {
    this.lastFetch = new Date();

    if (!value || (Array.isArray(value) && value.length === 0)) {
      if (!skipMarkingAsUnsaved)
        this._unsavedIds = this.unsavedInstances
          .filter((instance) => !instance.isNew)
          .map((instance) => -1 * instance.id);

      this._instances = [];

      return;
    }

    if (!Array.isArray(value)) value = [value];

    // // remove any that are just ids and already in the collection
    // value = (value as ICrudCollectionItemValue[]).filter(
    //   (item) =>
    //     !(
    //       (typeof item === "number" || typeof item === "string") &&
    //       this.hasItem(item as number) &&
    //       !this.isMarkedForDeletion(item as number)
    //     )
    // );

    // coerce and diff to append/remove
    const coercedValues = (value as ICrudCollectionItemValue[]).map((item) =>
      this.coerceItemValue(item)
    );

    const toReplace = coercedValues.filter(
      (item) => item && item.model && this.hasItem(item.id)
    );

    const toAppend = coercedValues.filter(
      (item) => item && !this.hasItem(item.id)
    );
    const toRemove = this.ids.filter(
      (id) => !coercedValues.find((item) => item?.id === id)
    );

    // replace
    toReplace.forEach((item) => {
      const index = this._instances.findIndex(
        (instance) => instance.id === item?.id
      );

      this._instances[index] = item?.model as CrudModel;
    });

    // remove
    toRemove.forEach((id) => this.removeItem(id, skipMarkingAsUnsaved));

    // append and return promise since that's asyncronous
    return Promise.all(
      (toAppend as ICrudCollectionItemValue[]).map((item) =>
        this.append(item, skipMarkingAsUnsaved)
      )
    );
  }

  // hold on to unhydrated ids until we have the model
  protected _unhydratedIds: number[] = [];

  public async append(
    value: ICrudCollectionItemValue,
    skipMarkingAsUnsaved = false
  ) {
    const coercedValue = this.coerceItemValue(value);
    if (!coercedValue || this.hasItem(coercedValue.id)) return;

    if (coercedValue.id) this._unhydratedIds.push(coercedValue.id);

    if (!skipMarkingAsUnsaved && !coercedValue.isNew)
      this._unsavedIds.push(coercedValue.id);

    return coercedValue?.modelPromise.then((model) => {
      if (!model) return;

      this._unhydratedIds = this._unhydratedIds.filter((id) => id !== model.id);
      this._instances.push(model);
    });
  }

  protected coerceItemValue(
    value: ICrudCollectionItemValue | CoercedCollectionItemValue
  ): CoercedCollectionItemValue | null {
    if (!value) return null;

    if (_.isPlainObject(value)) {
      // is it already coerced?
      if ((value as CoercedCollectionItemValue).modelPromise)
        return value as CoercedCollectionItemValue;

      // if it's a plain object, we need to create a new instance
      // @ts-ignore
      const newInstance = cache.getFromCache(this.model, value.id, value);
      return {
        id: newInstance.id,
        isNew: newInstance.isNew,
        model: newInstance,
        modelPromise: Promise.resolve(newInstance),
      };
    }

    if (value instanceof this.model) {
      return {
        id: value.id,
        isNew: value.isNew,
        model: value,
        modelPromise: Promise.resolve(value),
      };
    }

    if (typeof value === "number" || typeof value === "string") {
      const newInstanceFromId = cache.getFromCache(this.model, value);

      return {
        id: Number(value),
        isNew: Number.isNaN(Number(value)),
        modelPromise: newInstanceFromId.hydrate(),
      };
    }

    if (!(value instanceof this.model)) {
      console.error("value", value);
      console.error("expectedModelType", this.model);
      throw new Error(
        "CrudCollection.append() must be passed a CrudModel instance or json object"
      );
    }

    return null;
  }

  public hasItem(id: number) {
    return this.ids.includes(id);
  }

  public getItem(id: number) {
    return this.instances.find((model) => model.id === id);
  }

  public async deleteItem(id: number) {
    const instance = this.getItem(id);

    // it's getting deleted, so no need to pass along the removal
    this.removeItem(id, true);
    if (!instance) return;

    return !instance.isNew ? instance.delete() : null;
  }

  protected _toDelete: number[] = [];
  public get toDelete() {
    return this._toDelete;
  }

  public markItemForDeletion(id: number) {
    const instance = this.getItem(id);
    if (instance && instance.isNew) {
      this.removeItem(id);
      return;
    }

    this._toDelete.push(id);
  }

  public restoreItem(id: number) {
    this._toDelete = this._toDelete.filter((toDeleteId) => toDeleteId !== id);
  }

  public isMarkedForDeletion(id: number) {
    return this._toDelete.includes(id);
  }

  public removeItem(id: number, skipMarkingAsUnsaved = false) {
    this._instances = this._instances.filter((model) => model.id != id);

    if (!skipMarkingAsUnsaved) this._unsavedIds.push(id * -1);
  }

  public async saveItem(id: number) {
    return this.getItem(id)?.save();
  }

  public newModelDefaults?: Record<string, any> | Function;
  public newItem(opts = {}) {
    const optsWithCollectionStaticFilters = Object.assign(
      {},
      this.newModelDefaultsForCollection,
      opts
    );

    const newModel = new this.model(optsWithCollectionStaticFilters);
    this.append(newModel);

    return newModel;
  }

  public get newModelDefaultsForCollection() {
    const staticFiltersAsProperties = this.staticFilters
      .map((filter) => filter.asDefaultProperty())
      .filter((property) => property);

    let newModelDefaults = {};
    if (typeof this.newModelDefaults === "function")
      newModelDefaults = this.newModelDefaults();
    else if (typeof this.newModelDefaults === "object")
      newModelDefaults = this.newModelDefaults;

    return Object.assign(
      {},
      staticFiltersAsProperties.reduce(
        (acc, property) => ({
          ...acc,
          ...(property as CrudProperty).serializedPayload,
        }),
        {}
      ),
      newModelDefaults
    );
  }

  public get newItemRoute() {
    return (
      this.model.getRouteSingle() +
      "?" +
      new URLSearchParams(this.newItemDefaults).toString()
    );
  }

  public get newItemDefaults() {
    const nonEmptyFilterValues = Object.keys(this.filterQueryValues).reduce(
      (acc, key) => {
        if (
          this.filterQueryValues[key] !== undefined &&
          this.filterQueryValues[key] !== null
        )
          acc[key] = this.filterQueryValues[key];
        return acc;
      },
      {}
    );

    return {
      ...nonEmptyFilterValues,
      ...this.newModelDefaults,
    };
  }

  public async save() {
    return Promise.all(this.unsavedInstances.map((model) => model.save()));
  }

  public get isEmpty() {
    return (
      this.ids.length === 0 ||
      !this.instances.some(
        (instance) =>
          !this.isMarkedForDeletion(instance.id) && !instance.isEmpty
      )
    );
  }

  protected _hasUnsavedChanges = false;
  protected _unsavedIds: number[] = [];
  public get hasUnsavedChanges() {
    return (
      this.isHydrated &&
      (this._hasUnsavedChanges ||
        this.newInstances.length > 0 ||
        this.toDelete.length > 0 ||
        this._unsavedIds.length > 0 ||
        this.hasUnsavedInstances)
    );
  }

  public markAsSaved() {
    // remove items marked for deletion
    this._toDelete.forEach((id) => this.removeItem(id, true));
    this._toDelete = [];

    this._hasUnsavedChanges = false;
    this._unsavedIds = [];

    // buggy, so we'll refetch everything instead.
    // this.unsavedInstances.forEach(model => model.markAsSaved());
    this.forceFetch();
  }

  public isSaving = false;
  public async saveChanges() {
    this.isSaving = true;

    await Promise.all(
      this._toDelete.map((id) => {
        const instance = this.getItem(id);
        if (instance && !instance.isNew) return instance.delete();
      })
    );

    await Promise.all(
      this.unsavedInstances.map((instance) => {
        return instance.save();
      })
    );

    this.isSaving = false;
    this.markAsSaved();
  }

  /**
   * Returns a serialized representation of the collection's changes
   *
   * @example
   * [
   *   {
   *     "name": "New Item",
   *     "description": "This is a new item because it has no ID"
   *   },
   *   {
   *     "id": 42,
   *     "name": "This prop changed"
   *   },
   *   -13, // item with ID 13 was removed
   *   19, // item with ID 19 was added
   *   !20 // item with ID 20 should be deleted
   * ]
   *
   * @returns {object}
   *
   */
  public get serializedValue() {
    return [...this.serializedInstanceValues];
  }

  protected get changedValueItems() {
    return [
      // items that were edited and not then deleted
      ...this.unsavedInstances
        .filter((model) => !this.toDelete.includes(model.id))
        .map((model) => {
          return model.serializedChanges;
        }),

      // items marked for deletion
      ...this.toDelete
        // remove temp ids
        .filter((id) => String(id).indexOf("_") !== 0)
        .map((id) => "-" + id),
    ];
  }

  protected get serializedInstanceValues() {
    return [
      ...this.canonicalIds,
      ...this.unsavedInstances.map((model) => model.serializedChanges),
    ];
  }

  public get serializedChanges() {
    // if explicitly marked as unsaved, return all instances
    if (this._hasUnsavedChanges) return this.serializedInstanceValues;

    return [
      ...this.changedValueItems,
      // items added to the collection
      ...this._unsavedIds,
    ];
  }

  public get ids() {
    return [...this.instances.map((model) => model.id), ...this._unhydratedIds];
  }

  public get canonicalIds() {
    return this.ids.filter((id) => ("" + id).indexOf("_") === -1);
  }

  public get hasUnsavedInstances() {
    return this.instances.some((model) => model.hasUnsavedChanges);
  }

  public get unsavedInstances() {
    return this.instances.filter((model) => model.hasUnsavedChanges);
  }

  public get newInstances() {
    return this.instances.filter((model) => model.isNew);
  }

  public get deletedInstances() {
    return this._instances.filter((model) => model.isDeleted);
  }

  public get totalItems() {
    return Number(this.remoteTotalItems) > 0
      ? Number(this.remoteTotalItems) + this.newInstances.length
      : this.instances.length;
  }

  public setFilter(filterName: string, filterValue: any) {
    const filter = this.getFilter(filterName);

    if (filter) {
      const filterQueryBeforeUpdate = filter.query;

      filter.setQuery(filterValue);

      // fetch if its query value changed
      if (!_.isEqual(filterQueryBeforeUpdate, filter.query)) {
        this.applyStagedFilters();
        this.fetchDebounced();
      }
    } else {
      console.error("existing filters: ", this.remoteQueryFilters);
      throw new Error("Filter not found in collection: " + filterName);
    }
  }

  public getFilter(filterName: string) {
    if (filterName === this.dynamicFilterController.name)
      return this.dynamicFilterController;

    return this.remoteQueryFilters.find((filter) => filter.name === filterName);
  }

  public useFilters(filters: CrudFilter[], skipExisting = false) {
    if (!this.remoteQuery) return;

    if (skipExisting) {
      // if the filter isn't already in place, use it
      const newFilters = filters.filter((filter) => {
        return !this.remoteQueryFilters.find((existingFilter) =>
          existingFilter.overlapsFilter(filter)
        );
      });

      this.remoteQueryFilters = this.remoteQueryFilters.concat(newFilters);
    } else {
      // if the filter is already in place, replace it. otherwise add it.
      filters.forEach((filter) => {
        const existingFilterIndex = this.remoteQueryFilters.findIndex(
          (existingFilter) => existingFilter.name === filter.name
        );

        if (existingFilterIndex !== -1)
          this.remoteQueryFilters[existingFilterIndex] = filter;
        else this.remoteQueryFilters.push(filter);
      });
    }

    this.applyStagedFilters();
  }

  public clearFilters(includeStatic = false) {
    const filtersToClear = !includeStatic
      ? this.visibleFilters
      : this.staticFilters;

    filtersToClear.forEach((filter) => filter.clear());
  }

  public clearFiltersExcept(filterNamesToExclude: string[] = []) {
    const filtersToClear = this.remoteQueryFilters.filter(
      (filter) =>
        !filterNamesToExclude || !filterNamesToExclude.includes(filter.name)
    );
    filtersToClear.forEach((filter) => filter.clear());
  }

  public clearVisibleFiltersImmediately() {
    this.clearFilters();
    this.applyStagedFilters();
  }

  public get hasAppliedVisibleFilters() {
    return this.visibleFilters.some((filter) => filter.isSet());
  }

  public get staticFilters() {
    return this.remoteQueryFilters.filter((filter) => filter.isStatic);
  }

  public get visibleFilters() {
    return [
      ...this.visibleFiltersWithoutDynamicFilterController,
      this.dynamicFilterController,
    ];
  }

  public get visibleFiltersWithoutDynamicFilterController() {
    return this.remoteQueryFilters.filter((filter) => filter.isVisible);
  }

  public setQueryOption<T extends keyof ICollectionRemoteQueryOptions>(
    optionName: T,
    optionValue: ICollectionRemoteQueryOptions[T]
  ) {
    // ignore invalid optionNames
    if (!this.remoteQueryOptions.hasOwnProperty(optionName)) return;

    // transform sortby from string into property
    if (optionName === "sortBy") {
      // @ts-ignore
      optionValue = (optionValue as CrudPropertyQuery[]).map((prop) => {
        const asProperty = this.model.findProperty(prop);
        if (!asProperty)
          throw new Error(
            `Could not find property ${prop} on model ${this.model.typeLabel}`
          );

        return asProperty;
      });
    }

    // bail if it's already set as this value
    if (_.isEqual(this.remoteQueryOptions[optionName], optionValue)) return;

    // @ts-ignore
    this.remoteQueryOptions[optionName] = optionValue;

    // if it's a change to perpage and we're already hydrated with less than the new value, no need to fetch
    if (
      optionName === "perPage" &&
      // @ts-ignore
      this.instances.length < optionValue
    )
      return;

    this.fetchDebounced();
  }

  public toPlainObject(
    opts: IToPlainObjectOpts = {
      decorated: true,
      formatted: true,
    }
  ) {
    return this.instances.map((instance) => instance.toPlainObject(opts));
  }

  public filterQueryValues = {};
  public get stagedFilterQueryValues() {
    return [...this.remoteQueryFilters, this.dynamicFilterController]
      .map((filter) => filter.query)
      .filter((query) => query && Object.keys(query).length > 0)
      .reduce(
        (combinedQueries, filterQuery) => ({
          ...combinedQueries,
          ...filterQuery,
        }),
        {}
      );
  }

  public applyStagedFilters() {
    this.filterQueryValues = this.stagedFilterQueryValues;
  }

  public get hasUnappliedFilters() {
    return !_.isEqual(this.filterQueryValues, this.stagedFilterQueryValues);
  }

  public get optionQueryValues() {
    return {
      ...this.remoteQueryOptions,
      sortBy: this.remoteQueryOptions.sortBy?.map(
        (property) => property.serializedName
      ),
      sortDesc: this.remoteQueryOptions.sortDesc?.map((val) => (val ? 1 : 0)),
    };
  }

  protected get remoteQueryValues() {
    const optionQueries = this.optionQueryValues;

    // add underscore to the beginning of each option
    Object.keys(optionQueries).forEach((key) => {
      optionQueries["_" + key] = optionQueries[key];
      delete optionQueries[key];
    });

    return {
      ...this.filterQueryValues,
      ...optionQueries,
    };
  }

  protected _lastFetchQueryValues: any = {};
  public async fetch() {
    if (
      !this.remoteQuery ||
      _.isEqual(this.remoteQueryValues, this._lastFetchQueryValues)
    )
      return this.lastFetch;

    this._lastFetchQueryValues = this.remoteQueryValues;
    this.lastFetch = new Date();

    this.loading = true;
    this._instances = await this.model[this.fetchMethod](
      this.remoteQueryValues
    ).then((res) => {
      this.remoteTotalItems = res.meta ? res.meta.total : res.data.length;
      return res.dataHydrated;
    });

    this.loading = false;

    return this.lastFetch;
  }
  public fetchDebounced = _.debounce(this.fetch, 50);

  public get isHydrated() {
    return !!this.lastFetch;
  }

  public async fetchIfUnhydrated() {
    if (!this.isHydrated) {
      return this.fetchDebounced();
    }

    return this.lastFetch;
  }

  public forceFetch() {
    this._lastFetchQueryValues = {};
    return this.fetch();
  }
}
