import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";
import {
  DateTimeField,
  FileField,
  NumberField,
  RelationshipField,
  StringField,
} from "../plugins/Cruxtify/field-types";
import {
  DateTimeProperty,
  FileProperty,
  NumberProperty,
  RelationshipProperty,
  StringProperty,
} from "../plugins/Cruxtify/property-types";

export class AttachmentFile extends CrudModel {
  protected static _typeLabel = "Attachment File";
  protected static asProperty = "attachment-file";
  public static api = {
    path: "attachment-files",
  };

  protected static routeBase = "attachment-files";
  // public static modalEdit = true;
  protected userIdField = [];

  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
      [UserRole.EntityOwner]: UserPermission.Edit,
      [UserRole.LoggedIn]: UserPermission.New,
    },
    UserPermission.Read
  );

  protected static propertyDefinitions = [
    {
      type: NumberProperty,
      opts: {
        name: "id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "deleted_at",
        label: "Deleted At",
        userPermissions: UserPermission.Read,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "created_at",
        label: "Created At",
        userPermissions: UserPermission.Read,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "updated_at",
        label: "Updated At",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "basename",
        label: "Filename",
        isPrimaryLabelField: true,
        userPermissions: UserPermission.Edit,
        sortable: true,
        rules: ["required"],
      },
    },
    {
      type: FileProperty,
      opts: {
        name: "url",
        label: "File",
        userPermissions: UserPermission.Edit,
        prependUploadsUrl: true,
        rules: ["required"],
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "attachment",
        label: "Attachment",
        relatedModel: "Attachment",
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Edit,
          },
          UserPermission.New
        ),
      },
    },
  ];

  protected static fieldDefinitions = [
    { type: NumberField, opts: { property: "id" } },
    { type: DateTimeField, opts: { property: "created_at" } },
    { type: DateTimeField, opts: { property: "updated_at" } },
    { type: StringField, opts: { property: "basename" } },
    { type: FileField, opts: { property: "url" } },
    { type: RelationshipField, opts: { property: "attachment" } },
  ];
}
