import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";
import {
  BooleanField,
  DateTimeField,
  MoneyField,
  NumberField,
  RelationshipField,
  SelectField,
  StringField,
} from "../plugins/Cruxtify/field-types";
import {
  BooleanProperty,
  DateTimeProperty,
  MoneyProperty,
  NumberProperty,
  RelationshipProperty,
  StringProperty,
} from "../plugins/Cruxtify/property-types";

export class Expense extends CrudModel {
  protected static _typeLabel = "Expense";
  protected static asProperty = "expense";
  public static api = {
    path: "expenses",
  };
  protected static routeBase = "expenses";
  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
      [UserRole.EntityOwner]: UserPermission.Edit,
      [UserRole.LoggedIn]: UserPermission.New,
    },
    UserPermission.Read
  );

  protected static propertyDefinitions = [
    {
      type: NumberProperty,
      opts: {
        name: "id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "deleted_at",
        label: "Deleted At",
        userPermissions: UserPermission.Read,
      },
    },
    {
      type: NumberProperty,
      opts: {
        name: "legacy_id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "created_at",
        label: "Created At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "updated_at",
        label: "Updated At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "contract",
        label: "Contract",
        relatedModel: "Contract",
        sortable: true,
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "type",
        label: "Type",
        rules: ["required"],
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "name",
        label: "Name",
        rules: ["required"],
      },
    },
    {
      type: BooleanProperty,
      opts: {
        name: "show_on_invoice",
        label: "Show on Invoice",
      },
    },
    {
      type: MoneyProperty,
      opts: {
        name: "total",
        label: "Total",
      },
    },

    {
      type: RelationshipProperty,
      opts: {
        name: "last_mod_author",
        label: "Last Updated By",
        relatedModel: "User",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "author",
        label: "Author",
        relatedModel: "User",
        userPermissions: new UserPermissions(
          // {
          //   [UserRole.SuperAdmin]: UserPermission.Edit
          // },
          UserPermission.Read
        ),
        computed: true,
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "user",
        label: "Owner",
        relatedModel: "User",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
  ];

  protected static fieldDefinitions = [
    { type: NumberField, opts: { property: "id" } },
    { type: NumberField, opts: { property: "legacy_id" } },
    { type: DateTimeField, opts: { property: "created_at" } },
    { type: DateTimeField, opts: { property: "updated_at" } },
    { type: RelationshipField, opts: { property: "contract" } },
    {
      type: SelectField,
      opts: {
        property: "type",
        options: ["Materials", "Labor", "Fees", "Equipment"],
      },
    },
    { type: StringField, opts: { property: "name" } },
    { type: MoneyField, opts: { property: "total" } },
    { type: BooleanField, opts: { property: "show_on_invoice" } },
    { type: RelationshipField, opts: { property: "last_mod_author" } },
    { type: RelationshipField, opts: { property: "author" } },
    { type: RelationshipField, opts: { property: "user" } },
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      columnFields: [
        "created_at",
        "contract",
        "type",
        "name",
        "total",
        "show_on_invoice",
      ],
      useDynamicFilters: true,
      dynamicFilterOptions: {
        filterFields: [
          "contract",
          "type",
          "show_on_invoice",
          "total",
          "created_at",
        ],
      },
      useModal: true,
      defaultSort: [
        {
          field: "created_at",
          order: "desc",
        },
      ],
    },

    {
      id: "asRelationship",
      columnFields: ["type", "name", "total", "show_on_invoice"],
      inlineEdit: true,
    },
  ];

  protected static modelLayoutDefinitions = [
    {
      id: "Crudform",
      fieldSets: [
        {
          id: "0",
          fields: ["contract"],
        },
        {
          id: "1",
          fields: ["type", "name", "total", "show_on_invoice"],
        },
        {
          id: "meta",
          fields: ["created_at", "updated_at", "author", "last_mod_author"],
        },
      ],
    },
  ];
}
